import * as _ from 'underscore';
import invariant from 'invariant';

import {
  CardSubType,
  CardType,
  cardCharToCardType,
  getCardSubTypeChar,
  isCardSubType,
  isCardType,
  maybeCharToCardSubType,
} from './CardTypes';
import DescriptionTypes, { DescriptionType } from './DescriptionTypes';
import * as Utility from './Utility';
import {
  CharacterToResourceName,
  isToken,
  NameToResourceCharacter,
  Resource,
  Token,
} from './Resources';
import { Context } from './Rules';
import { CardDef } from './GameModel';
import { P, match } from 'ts-pattern';
import { InflatedPlayer } from './Game';

export abstract class BaseCardAbility {
  private _outputs: AbilityOutput[];
  constructor(outputString: string) {
    this._outputs = parseAbilityOutputs(outputString);
  }

  public get outputs(): AbilityOutput[] {
    return this._outputs;
  }
}
export class GainSelfAbility extends BaseCardAbility {
  public onGainSelf(context: Context): Utility.CounterDelta {
    return computeAbilityOutputs(this.outputs, context);
  }
}
export class EachTurnAbility extends BaseCardAbility {
  public onTurn(context: Context): Utility.CounterDelta {
    return computeAbilityOutputs(this.outputs, context);
  }
}
export class BaseCountersAbility extends BaseCardAbility {
  public getBaseCounters(context: Context): Utility.CounterDelta {
    return computeAbilityOutputs(this.outputs, context);
  }
}

export class EndOfGameAbility extends BaseCardAbility {
  public onEndOfGame(context: Context): Utility.CounterDelta {
    return computeAbilityOutputs(this.outputs, context);
  }
}

// XXX(mythos3): draft left/right
export type DraftTarget = CardType;
export class DraftCardAbility extends BaseCardAbility {
  private _draftTarget: DraftTarget;

  constructor(effectString: string) {
    const pattern = /\+([PRC])(.*)/;
    const match = pattern.exec(effectString);
    invariant(match, 'invalid draft card ability: %s', effectString);
    const cardChar = match[1];
    const cardType = cardCharToCardType(cardChar);
    const outputString = match[2];

    super(outputString);

    this._draftTarget = cardType;
  }

  public onDraftCard(
    context: Context,
    draftedCard: CardDef,
  ): Utility.CounterDelta {
    if (draftedCard.type !== this._draftTarget) {
      return Utility.makeCounterDelta();
    }
    return computeAbilityOutputs(this.outputs, context);
  }

  public getDraftTarget(): DraftTarget {
    return this._draftTarget;
  }
}

export type GainTargetType = CardType | 'tribute' | Token;
export class GainOtherCardAbility extends BaseCardAbility {
  private _targetType: GainTargetType;

  constructor(effectString: string) {
    const pattern = /\+([PRCBTW])(.*)/;
    const result = pattern.exec(effectString);
    invariant(result, 'invalid gain card ability: %s', effectString);
    const cardChar = result[1];
    const targetType = match(cardChar)
      .with('T', () => 'tribute' as const)
      .with('W', () => Token.War as const)
      .otherwise(cardCharToCardType);
    const outputString = result[2];

    super(outputString);

    this._targetType = targetType;
  }
  public onGainCard(
    context: Context,
    gainedCard: CardDef,
  ): Utility.CounterDelta {
    if (!isCardType(this._targetType) || gainedCard.type !== this._targetType) {
      return Utility.makeCounterDelta();
    }
    return computeAbilityOutputs(this.outputs, context);
  }
  public onGainTribute(context: Context): Utility.CounterDelta {
    if (this._targetType !== 'tribute') {
      return Utility.makeCounterDelta();
    }

    return computeAbilityOutputs(this.outputs, context);
  }
  public onGainToken(context: Context, token: Token): Utility.CounterDelta {
    if (!isToken(this._targetType) || this._targetType !== token) {
      return Utility.makeCounterDelta();
    }

    return computeAbilityOutputs(this.outputs, context);
  }

  getTargetType(): GainTargetType {
    return this._targetType;
  }
}

export function getBaseCountersAbility(
  card: CardDef,
): BaseCountersAbility | null {
  if (!card.baseEffect) {
    return null;
  }
  return new BaseCountersAbility(card.baseEffect);
}

export function getGainSelfCardAbility(card: CardDef): GainSelfAbility | null {
  if (!card.gainSelfEffect) {
    return null;
  }
  return new GainSelfAbility(card.gainSelfEffect);
}
export function getEachTurnCardAbility(card: CardDef): EachTurnAbility | null {
  if (!card.turnEffect) {
    return null;
  }
  return new EachTurnAbility(card.turnEffect);
}
export function getDraftCardAbility(card: CardDef): DraftCardAbility | null {
  if (!card.draftOtherEffect) {
    return null;
  }
  return new DraftCardAbility(card.draftOtherEffect);
}
export function getGainOtherCardAbility(
  card: CardDef,
): GainOtherCardAbility | null {
  if (!card.gainOtherEffect) {
    return null;
  }
  return new GainOtherCardAbility(card.gainOtherEffect);
}
export function getEndOfGameCardAbility(
  card: CardDef,
): EndOfGameAbility | null {
  if (!card.endOfGameEffect) {
    return null;
  }
  return new EndOfGameAbility(card.endOfGameEffect);
}

export type CardAdditionalCosts = {
  warTokens: number;
};
export function getAdditionalCosts(card: CardDef): CardAdditionalCosts | null {
  if (!card.additionalCosts) {
    return null;
  }
  const pattern = /(\d*)([W])/;
  const match = pattern.exec(card.additionalCosts);
  if (!match) {
    return null;
  }
  return {
    warTokens: parseInt(match[1] || '1'),
  };
}

export type AbilityOutputMultiplierType =
  | CardType
  | CardSubType
  | 'card_set'
  | Token;
export type AbilityOutputMultiplier = {
  resource: AbilityOutputMultiplierType;
  // ratio: number;
  resourceDivisor: number;
};

export function abilityUpgradeConditionToString(
  condition: AbilityUpgradeCondition,
): string {
  return `${condition.upgradeAmount}${divisorTypeToDivisorChar(condition.upgradeType)}`;
}
export type AbilityUpgradeCondition = {
  upgradeType: AbilityOutputMultiplierType;
  upgradeAmount: number;
};

export type AbilityOutput = {
  outputResource: Resource;
  outputRatio: number;

  multiplier?: AbilityOutputMultiplier;
  upgradeCondition?: AbilityUpgradeCondition;
};
function divisorCharToDivisorType(char: string): AbilityOutputMultiplierType {
  if (char === 'S') {
    return 'card_set';
  }
  if (char === 'W') {
    return Token.War;
  }
  const subtype = maybeCharToCardSubType(char);
  if (subtype) {
    return subtype;
  }
  return cardCharToCardType(char);
}

function divisorTypeToDivisorChar(type: AbilityOutputMultiplierType): string {
  if (type === 'card_set') {
    return 'S';
  }
  if (type === Token.War) {
    return 'W';
  }
  if (isCardSubType(type)) {
    return getCardSubTypeChar(type);
  }
  return type[0].toUpperCase();
}

function parseAbilityOutputs(outputs: string): AbilityOutput[] {
  return outputs.split(' ').map(parseAbilityOutput).flat();
}

function parseAbilityOutput(output: string): AbilityOutput {
  const synergyPattern = /(\d*)([GMV])\/(\d*)([PRCSDUEW])/;
  const flatPattern = /(\d*)([GMV])/;
  const upgradePattern = /(\d*)([GMV])>(\d*)([PRCSDUEW])/;

  const synergyMatch = synergyPattern.exec(output);
  if (synergyMatch) {
    const resourceChar = synergyMatch[2];
    const resource = CharacterToResourceName[resourceChar];
    const resourceAmount = parseInt(synergyMatch[1] || '1');
    const divisorAmount = parseInt(synergyMatch[3] || '1');
    const divisorResourceChar = synergyMatch[4];
    const divisorResource = divisorCharToDivisorType(divisorResourceChar);
    return {
      outputResource: resource,
      outputRatio: resourceAmount,
      multiplier: {
        resource: divisorResource,
        resourceDivisor: divisorAmount,
      },
    };
  }

  const upgradeMatch = upgradePattern.exec(output);
  if (upgradeMatch) {
    const resourceChar = upgradeMatch[2];
    const resource = CharacterToResourceName[resourceChar];
    const resourceAmount = parseInt(upgradeMatch[1] || '1');
    const upgradeAmount = parseInt(upgradeMatch[3] || '1');
    const upgradeTypeChar = upgradeMatch[4];
    const upgradeType = divisorCharToDivisorType(upgradeTypeChar);
    return {
      outputResource: resource,
      outputRatio: resourceAmount,
      upgradeCondition: {
        upgradeType: upgradeType,
        upgradeAmount: upgradeAmount,
      },
    };
  }

  const match = flatPattern.exec(output);
  if (match) {
    const resourceChar = match[2];
    const resource = CharacterToResourceName[resourceChar];
    const resourceAmount = parseInt(match[1] || '1');
    return {
      outputResource: resource,
      outputRatio: resourceAmount,
    };
  }

  invariant(false, 'unable to parse ability output: %s', output);
}

function computeAbilityOutputs(
  abilityOutputs: AbilityOutput[],
  context: Context,
): Utility.CounterDelta {
  return _.reduce(
    abilityOutputs,
    (output, abilityOutput) => {
      const delta = computeAbilityOutput(abilityOutput, context);
      return Utility.applyCounterDelta(output, delta);
    },
    Utility.makeCounterDelta(),
  );
}

function computeAbilityOutput(
  abilityOutput: AbilityOutput,
  context: Context,
): Utility.CounterDelta {
  const output = Utility.makeCounterDelta();

  const upgradeCondition = abilityOutput.upgradeCondition;
  if (upgradeCondition && !upgradeConditionMet(upgradeCondition, context)) {
    return output;
  }

  const outputResource = abilityOutput.outputResource;
  const outputRatio = abilityOutput.outputRatio;

  let multiplier = 1;
  if (abilityOutput.multiplier) {
    const base = getPlayerMultiplier(
      abilityOutput.multiplier.resource,
      context,
    );
    multiplier = Math.floor(base / abilityOutput.multiplier.resourceDivisor);
  }

  output[outputResource] = outputRatio * multiplier;

  return output;
}

export function upgradeConditionMet(
  condition: AbilityUpgradeCondition,
  context: Context,
): boolean {
  const base = getPlayerMultiplier(condition.upgradeType, context);
  return base >= condition.upgradeAmount;
}

function getPlayerMultiplier(
  multiplierType: AbilityOutputMultiplierType,
  context: Context,
): number {
  return match(multiplierType)
    .with('card_set', () => Utility.countBoardSets(context.player.cards))
    .with(Token.War, () => context.player.counters.warTokens)
    .when(isCardSubType, (subType) =>
      countCardsOfSubType(context.player.cards, subType),
    )
    .when(isCardType, (cardType) =>
      countCardsOfType(context.player.cards, cardType),
    )
    .otherwise((x) => {
      invariant(false, 'invalid multiplier resource: %s', x);
    });
}

function countCardsOfType(cards: CardDef[], type: CardType): number {
  return Utility.countIf(cards, (card) => card.type === type);
}

function countCardsOfSubType(cards: CardDef[], subType: CardSubType): number {
  return Utility.countIf(cards, (card) => card.subType === subType);
}
