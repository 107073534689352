export type GameOptions = {
  tradeRowSlide: boolean;
  prerollDice: boolean;
  randomLeader: boolean;
  biggerTradeRow: boolean;
  wipeTradeRow: boolean;
  warTokens: boolean;
  rerollTokens: boolean;
  hideFavor: boolean;

  // tutorial: boolean;
};
export type GameOptionsKey = keyof GameOptions;

export function getDefaultGameOptions(): GameOptions {
  return {
    tradeRowSlide: false,
    prerollDice: false,
    randomLeader: false,
    biggerTradeRow: false,
    wipeTradeRow: false,

    warTokens: false,
    rerollTokens: false,

    hideFavor: false,

    // tutorial: false,
  };
}

export type GameOptionInfo = {
  name: string;
  key: string;
  tooltip: string;
  showInLobby?: boolean;
};
export function getGameOptionData(): Record<GameOptionsKey, GameOptionInfo> {
  return {
    randomLeader: {
      name: 'Random Leader',
      key: 'randomleader',
      tooltip: 'Randomly select a starting leader for each player.',
      showInLobby: true,
    },
    prerollDice: {
      name: 'Preroll Dice',
      key: 'preroll',
      tooltip: 'Shows your dice rolls to you during the planning phase.',
      showInLobby: true,
    },
    tradeRowSlide: {
      name: 'Slide Trade Row',
      key: 'slide',
      tooltip:
        'Remove the rightmost card on the trade row at the end of each turn if it is not drafted.  Keeps trade row fresher.',
      // showInLobby: true, // NOTE(zack): disabled for cleanliness for now
    },
    biggerTradeRow: {
      name: 'Bigger Trade Row',
      key: 'biggertraderow',
      tooltip: 'Increase the size of the trade row by 1 to num players + 2',
      // showInLobby: true, // NOTE(zack): disabled for cleanliness for now
    },
    wipeTradeRow: {
      name: 'Wipe Trade Row',
      key: 'wipetraderow',
      tooltip:
        'Wipe the trade row at the end of each turn.  Recommended for 2p.',
    },
    warTokens: {
      name: 'War Tokens',
      key: 'wartokens',
      tooltip: 'Enable war tokens.',
    },
    rerollTokens: {
      name: 'Reroll Tokens',
      key: 'rerolltokens',
      tooltip:
        'Enable rerolls. Reroll tokens are gained on a L or L+ Clash result.',
      showInLobby: true,
    },
    hideFavor: {
      name: 'Hide Favor',
      key: 'hidefavor',
      tooltip: 'Hide Favor until the end of the game.',
      showInLobby: true,
    },

    // tutorial: {
    //   name: 'Tutorial',
    //   key: 'tutorial',
    //   tooltip: 'Runs a tutorial.',
    // },
  };
}
