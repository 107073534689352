import { GameOptionsKey } from './GameOptions';

export type GameModule = {
  key: string;
  name: string;
  description: string;

  gameOptionsToAdd: GameOptionsKey[];

  replaceCore?: boolean;
  disabled?: boolean;

  // for debugging and testing patches
  // cardNamesToRemove?: string[];
  // tributeNamesToRemove?: string[];
};

export const GameModules: GameModule[] = [
  {
    key: 'conquest',
    name: 'Conquest',
    description:
      'Introduces Clash tokens, which are gained whenever you Clash with a foe. Spend these to obtain powerful cards, or save them to synergize with cards in later ages.',

    gameOptionsToAdd: ['warTokens'],
    disabled: true,
  },
  {
    key: 'eog',
    name: 'EOG Effects',
    description: 'Adds cards with end of game synergy effects.',
    gameOptionsToAdd: [],
    replaceCore: true,
  },
];
