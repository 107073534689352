/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import _ from 'underscore';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import LobbyMutator from './LobbyMutator';
import PageHeaderView from './PageHeaderView';
import {
  fetchLobby,
  subscribeToChannelTopic,
  unsubscribeToChannelTopic,
} from './actions';
import { formatDuration } from './ViewUtilities';
import Session from './Session';
import { PlayableBots } from '../game/BotUtilities';
import { GameOptionInfo, getGameOptionData } from '../game/GameOptions';

import { useNavigate, useParams } from 'react-router-dom';
import LobbyButton from './LobbyButton';
import { GameModules } from '../game/GameModules';
import LogoBanner from './LogoBanner';
import nullthrows from 'nullthrows';

type Lobby = {
  game?: any;
  players: { edges: any[]; nodes: any[] };
  host: any;
  name: string;
  options: string;
  modules: string[];
};

function LobbyPage(props: {
  dispatch: any;
  session?: Session;
  lobbyByID: Map<string, Lobby>;
}) {
  const { dispatch, session, lobbyByID } = props;
  const params = useParams();
  const lobbyID = params.lobbyID!;
  const lobby = lobbyByID.get(lobbyID);
  const isHost = session?.getUserID() === lobby?.host.id;
  const inLobby = lobby?.players.edges.some(
    (edge) => edge.node.id === session?.getUserID(),
  );

  useEffect(() => {
    // XXX this might overfetch but it should prevent stale data
    dispatch(fetchLobby(lobbyID, Infinity));
  }, [lobbyID]);
  useEffect(() => {
    const subscription = dispatch(
      subscribeToChannelTopic(`lobby/${lobbyID}`, (message: any) => {
        dispatch(fetchLobby(lobbyID, message.sequenceID));
      }),
    );
    return () => {
      dispatch(unsubscribeToChannelTopic(subscription));
    };
  }, [lobbyID, dispatch]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!session) {
      navigate('/');
      return;
    }
    if (lobby && lobby.game) {
      navigate('/game/' + lobby.game.id, { replace: true });
    }
  }, [lobby, session, navigate]);

  const onStartGame = useCallback(async () => {
    const mutator = new LobbyMutator(session, lobbyID);
    const gameID = await mutator.startGame();
    navigate('/game/' + gameID, { replace: true });
  }, [session, lobbyID, navigate]);

  const [botName, setBotName] = React.useState(Object.keys(PlayableBots)[0]);
  const onAddBot = useCallback(() => {
    var mutator = new LobbyMutator(session, lobbyID);
    mutator.addBot(botName);
  }, [session, lobbyID, botName]);

  const onLeave = useCallback(async () => {
    const mutator = new LobbyMutator(session, lobbyID);
    await mutator.leaveLobby();
    navigate('/');
  }, [session, lobbyID, navigate]);

  const onKick = useCallback(
    async (player_id: string) => {
      const mutator = new LobbyMutator(session, lobbyID);
      await mutator.kickPlayer(player_id);
    },
    [session, lobbyID],
  );

  const onJoin = useCallback(async () => {
    const mutator = new LobbyMutator(session, lobbyID);
    await mutator.joinGame();
  }, [session, lobbyID]);

  const setBotNameCB = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBotName(
      _.findKey(PlayableBots, (bot) => {
        return bot.name === e.target.value;
      }),
    );
  };

  const setGameOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    let mutator = new LobbyMutator(session, lobbyID);
    mutator.setGameOption(key, e.target.checked);
  };

  const setModuleEnabled = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    let mutator = new LobbyMutator(session, lobbyID);
    mutator.setModule(key, e.target.checked);
  };

  if (!lobby || !cardsetList) {
    return <div>Loading lobby...</div>;
  }
  const user_entries = lobby.players.edges.map((edge) => {
    const { node } = edge;
    const profile_picture = (
      <img css={LobbyStyles.profilePicture} src={node.profilePictureURL} />
    );
    const blame_time = node.stats.totalBlameTime / node.stats.totalGames || 0;
    return (
      <li key={node.id} css={LobbyStyles.lobbyPlayerEntry}>
        <span css={LobbyStyles.lobbyPlayerEntryProfile}>
          {profile_picture}
          {node.name}
        </span>
        <span css={LobbyStyles.lobbyPlayerEntryTime}>
          Average Blame Time: {`${formatDuration(blame_time)}`}
        </span>
        <span css={LobbyStyles.lobbyPlayerEntryWins}>
          Wins: {`${node.stats.totalWins || 0}`}
        </span>
        {isHost && node.id !== session?.getUserID() && (
          <LobbyButton
            css={LobbyStyles.lobbyControlButton}
            secondary={true}
            onClick={() => onKick(node.id)}
          >
            Kick
          </LobbyButton>
        )}
      </li>
    );
  });
  let leave_button = inLobby && (
    <LobbyButton
      css={LobbyStyles.lobbyControlButton}
      secondary={true}
      onClick={onLeave}
    >
      Leave
    </LobbyButton>
  );
  let join_button = !inLobby && (
    <LobbyButton
      css={LobbyStyles.lobbyControlButton}
      secondary={true}
      onClick={onJoin}
    >
      Join
    </LobbyButton>
  );
  let controls = <></>;
  let game_options = JSON.parse(lobby.options);
  let modules = lobby.modules;
  let option_data = getGameOptionData();

  _.each(option_data, (option: GameOptionInfo, key: string) => {
    if (!option.showInLobby) {
      delete option_data[key as keyof typeof option_data];
    }
  });

  if (isHost) {
    let start_button = (
      <LobbyButton
        key="start"
        css={LobbyStyles.lobbyControlButton}
        onClick={onStartGame}
      >
        Start Game
      </LobbyButton>
    );
    let add_bot_button = (
      <LobbyButton
        key="bot"
        secondary={true}
        css={LobbyStyles.lobbyControlButton}
        onClick={onAddBot}
      >
        Add a Bot
      </LobbyButton>
    );
    let bot_options = _.map(PlayableBots, (bot) => {
      return (
        <option key={bot.name} value={bot.name}>
          {bot.name}
        </option>
      );
    });
    let add_bot_selector = (
      <select
        name="botselect"
        id="botselect"
        key="botselector"
        className="selector selector-dark"
        defaultValue={
          nullthrows(PlayableBots[botName as keyof typeof PlayableBots]).name
        }
        onChange={setBotNameCB}
      >
        {bot_options}
      </select>
    );

    let game_options_view = _.map(option_data, (option, key) => {
      return (
        <div css={LobbyStyles.lobbyGameOption} key={key}>
          <span
            className="lobby-game-option-tooltip"
            css={LobbyStyles.lobbyGameOptionTooltip}
          >
            {option.tooltip}
          </span>
          {option.name}:
          <input
            css={LobbyStyles.lobbyGameOptionCheckbox}
            type="checkbox"
            checked={game_options[key]}
            onChange={(e) => setGameOption(e, option.key)}
          />
        </div>
      );
    });
    const modules_view = GameModules.map((module) => {
      if (module.disabled) {
        return null;
      }
      return (
        <div css={LobbyStyles.lobbyGameOption} key={module.key}>
          <span
            className="lobby-game-option-tooltip"
            css={LobbyStyles.lobbyGameOptionTooltip}
          >
            {module.description}
          </span>
          {module.name}:
          <input
            css={LobbyStyles.lobbyGameOptionCheckbox}
            type="checkbox"
            checked={modules.includes(module.key)}
            onChange={(e) => setModuleEnabled(e, module.key)}
          />
        </div>
      );
    });
    controls = (
      <>
        <div css={LobbyStyles.lobbyControls}>
          {leave_button}
          {join_button}
          {start_button}
        </div>
        <ul css={LobbyStyles.lobbySettingsTable}>
          <li css={LobbyStyles.lobbySettingsRow}>
            <span css={LobbyStyles.lobbySettingsCellText}>Bots:</span>
            <span css={LobbyStyles.lobbySettingsCellSelect}>
              {add_bot_selector}
            </span>
            <span css={LobbyStyles.lobbySettingsCellButton}>
              {add_bot_button}
            </span>
          </li>
          <li css={LobbyStyles.lobbyGameOptionText}>Modules:</li>
          <li css={LobbyStyles.lobbyGameOptionText}>{modules_view}</li>
          <li css={LobbyStyles.lobbyGameOptionText}>Game Options:</li>
          <li css={LobbyStyles.lobbyGameOptionText}>{game_options_view}</li>
        </ul>
      </>
    );
  } else {
    let game_options_view = _.map(option_data, (option, key) => {
      return (
        <div css={LobbyStyles.lobbyGameOption}>
          <span css={LobbyStyles.lobbyGameOptionTooltip}>{option.tooltip}</span>
          {option.name}:{' '}
          <span css={LobbyStyles.lobbyGameOptionValue}>
            {game_options[key] ? 'yes' : 'no'}
          </span>
        </div>
      );
    });
    const modules_view = lobby.modules.map((module) => {
      const moduleDef = GameModules.find((x) => x.key === module);
      if (!moduleDef) {
        return null;
      }
      return (
        <div css={LobbyStyles.lobbyGameOption}>
          <span css={LobbyStyles.lobbyGameOptionTooltip}>
            {moduleDef.description}
          </span>
          <span css={LobbyStyles.lobbyGameOptionValue}>{moduleDef.name}</span>
        </div>
      );
    });
    controls = (
      <>
        <div css={LobbyStyles.lobbyControls}>
          {leave_button}
          {join_button}
        </div>
        <ul css={LobbyStyles.lobbySettingsTable}>
          <li css={LobbyStyles.lobbyGameOptionText}>Modules:</li>
          <li css={LobbyStyles.lobbyGameOptionText}>{modules_view}</li>
          <li css={LobbyStyles.lobbyGameOptionText}>Game Options:</li>
          <li css={LobbyStyles.lobbyGameOptionText}>{game_options_view}</li>
        </ul>
      </>
    );
  }
  return (
    <div>
      <PageHeaderView session={session} />
      <LogoBanner />
      <div css={LobbyStyles.lobbyPageContainer}>
        <div css={LobbyStyles.lobbyContainer}>
          <span css={LobbyStyles.lobbyHeader}>{lobby.name}</span>
          <ul css={LobbyStyles.lobbyPlayerList}>{user_entries}</ul>
          <div>{controls}</div>
        </div>
      </div>
    </div>
  );
}

const LobbyStyles = {
  lobbyPageContainer: css({
    padding: '0px 20px 0px 20px',
  }),
  lobbyContainer: css({
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: '30px',
    maxWidth: '820px',
  }),
  lobbyHeader: css({
    padding: '14px',
    textAlign: 'center',
    color: 'rgb(231, 231, 231)',
    backgroundColor: 'rgb(95, 95, 95)',
    borderBottom: '1px solid rgb(85, 85, 85)',
  }),
  lobbyPlayerList: css({
    height: '230px',
    backgroundColor: 'rgb(80, 80, 80)',
    width: '100%',
    paddingBottom: '3px',
  }),
  lobbyPlayerEntry: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgb(90, 90, 90)',
    backgroundColor: 'rgb(120, 120, 120)',
    height: '20%',
  }),
  lobbyPlayerEntryProfile: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '40%',
    paddingLeft: '3px',
  }),
  lobbyPlayerEntryTime: css({
    width: '50%',
  }),
  lobbyPlayerEntryWins: css({
    width: '10%',
  }),
  lobbyControls: css({
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    justifyContent: 'space-between',
    padding: '10px',
    backgroundColor: 'rgb(95, 95, 95)',
  }),
  lobbySettingsTable: css({
    width: '100%',
    backgroundColor: 'rgb(95, 95, 95)',
    paddingTop: '6px',
    paddingBottom: '10px',
  }),
  lobbySettingsRow: css({
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  lobbySettingsCellText: css({
    display: 'flex',
    flexDirection: 'row',
    color: 'rgb(227, 198, 101)',
    width: '10%',
    paddingLeft: '15px',
  }),
  lobbySettingsCellSelect: css({
    width: '22%',
  }),
  lobbySettingsCellButton: css({
    width: '22%',
  }),
  lobbyControlButton: css({
    padding: '5px 30px 5px 30px',
    minWidth: '100px',
  }),
  profilePicture: css({
    height: '32px',
    width: '32px',
    padding: '4px',
    paddingRight: '6px',
  }),
  lobbyGameOptionText: css({
    color: 'rgb(227, 198, 101)',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '10px',
    alignItems: 'center',
    marginLeft: '15px',
    marginTop: '10px',
  }),
  lobbyGameOption: css({
    display: 'flex',
    flexDirection: 'row',
    minWidth: '15%',
    marginRight: '15px',
    '&:hover .lobby-game-option-tooltip': {
      visibility: 'visible',
    },
  }),
  lobbyGameOptionTooltip: css({
    visibility: 'hidden',
    maxWidth: '250px',
    backgroundColor: 'rgb(67, 67, 67)',
    color: 'rgb(231, 231, 231)',
    textAlign: 'left',
    borderRadius: '2px',
    marginTop: '25px',
    padding: '5px 5px',
    position: 'absolute',
    zIndex: '1',
  }),
  lobbyGameOptionCheckbox: css({
    marginLeft: '5px',
    marginTop: '0px',
  }),
  lobbyGameOptionValue: css({
    color: 'rgb(231, 231, 231)',
    marginLeft: '5px',
    minWidth: '30px',
  }),
};

const session = (state: any) => state.session as Session | undefined;
const lobbyByID = (state: any) => state.lobbyByID as Map<string, Lobby>;
const cardsetList = (state: any) => state.cardsetList as string[];
function select(state: any) {
  return {
    session: session(state),
    lobbyByID: lobbyByID(state),
    cardsetList: cardsetList(state),
  };
}

export default connect(select)(LobbyPage);
