/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React, { useMemo } from 'react';
import _ from 'underscore';

import * as Probability from '../game/Probability';

import CardView, { BasicCardRenderer } from './CardView';
import TributeCardView, {
  MakeHighlightedAgeTributeRenderer,
} from './TributeCardView';
import HoverCardStore, { HoverCardRenderer } from './HoverCardStore';
import DieView from './DieView';
import Symbols, {
  ResourceToSymbol,
  SymbolView,
  TokenToSymbol,
} from './Symbols';
import { InflatedGame } from '../game/Game';
import ActionStore from './ActionStore';
import { Resource, Resources } from '../game/Resources';
import { CounterDelta } from '../game/Utility';
import {
  ConflictRoundInfo,
  ConflictRoundPlayerInfo,
  EventTypes,
  GameEvent,
  GameEventOfType,
} from '../game/GameEvents';
import { match } from 'ts-pattern';
import {
  CardWithID,
  endOfGamePointsPerToken,
  TributeCardWithID,
} from '../game/Rules';
import { GameOptions } from '../game/GameOptions';

type User = any;

const GROUPED_EVENT_TYPES = new Set([
  EventTypes.GAIN_CARD,
  EventTypes.GAIN_TRIBUTES,
  EventTypes.DEAL_CARD,
  EventTypes.DEAL_TRIBUTE,
  EventTypes.SLIDE_CARD,
  EventTypes.PRODUCTION,
  EventTypes.BID,
  EventTypes.EARN_TOKEN,
  EventTypes.END_GAME_EFFECTS,
  EventTypes.END_GAME_SCORING,
]);

const LogTrimColor_War = 'rgb(255, 165, 130)';
const LogTrimColor_Favor = 'rgb(150, 130, 255)';
const LogTrimColor_Gold = 'rgb(240, 200, 95)';
const LogTrimColor_Production = 'rgb(134, 202, 125)';
const LogTrimColor_Neutral = 'rgb(170, 170, 170)';

type EventGroup<T extends EventTypes> = {
  type: T;
  events: GameEventOfType<T>[];
  key: string;
};
type EventRenderer<T extends EventTypes> =
  | {
      symbol: Symbols;
      symbolStyle?: React.CSSProperties;
      borderColor: string;
      bodyRenderer: (
        eg: EventGroup<any>,
        context: {
          userByID: { [k: string]: User };
          cardByID: { [k: string]: CardWithID };
          tributeCardByID: { [k: string]: TributeCardWithID };
          options: GameOptions;
        },
      ) => React.ReactNode;
    }
  | {
      itemRenderer: (eg: EventGroup<T>) => React.ReactElement | null;
    };
const EventTypeToRenderer: Record<EventTypes, EventRenderer<EventTypes>> = {
  [EventTypes.SET_UP_AGE]: { itemRenderer: () => null },
  [EventTypes.SET_UP_TURN]: {
    itemRenderer: (eg) => {
      return (
        <ChatLogDivider
          text={`Turn ${eg.events[0].turn}`}
          style={
            (eg.events[0].turn === 1 && {
              backgroundColor: 'rgba(180, 210, 235, 0.75)',
            }) ||
            undefined
          }
        />
      );
    },
  },
  [EventTypes.SET_UP_PHASE]: { itemRenderer: () => null },
  [EventTypes.END_OF_PHASE]: { itemRenderer: () => null },
  [EventTypes.BID]: {
    symbol: Symbols.M3_DRAFT,
    borderColor: LogTrimColor_War,
    bodyRenderer: (eg: EventGroup<EventTypes.BID>, { userByID, cardByID }) => {
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        const card = cardByID[e.payload.cardID];
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> bid{' '}
            {renderCounterDelta(e.payload, 'military')} on{' '}
            <CardNameView card={card} renderer={BasicCardRenderer} />
          </div>
        );
      });
    },
  },
  [EventTypes.CONFLICT_RESULTS]: {
    symbol: Symbols.MILITARY,
    borderColor: LogTrimColor_War,
    bodyRenderer(
      eg: EventGroup<EventTypes.CONFLICT_RESULTS>,
      { userByID, cardByID },
    ) {
      const event = eg.events[0];
      const firstRound = event.payload.rounds[0];
      if (Object.keys(firstRound).length === 1) {
        return null;
      }
      return (
        <ConflictResultsView
          event={event}
          userByID={userByID}
          cardByID={cardByID}
        />
      );
    },
  },
  [EventTypes.EARN_TOKEN]: {
    symbol: Symbols.ACQUIRE_TOKEN_WAR,
    borderColor: LogTrimColor_War,
    bodyRenderer: (
      eg: EventGroup<EventTypes.EARN_TOKEN>,
      { userByID, cardByID },
    ) => {
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        const token = e.payload.token;
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> gained{' '}
            <SymbolView
              symbol={TokenToSymbol[token]}
              css={CounterDeltaStyles.symbol}
            />
            {e.payload.triggeredCards.cardIDs.length > 0 && (
              <>
                {' '}
                and triggered{' '}
                <CardsNameView
                  cards={e.payload.triggeredCards.cardIDs.map(
                    (id) => cardByID[id],
                  )}
                  renderer={BasicCardRenderer}
                />{' '}
                for {renderCounterDelta(e.payload.triggeredCards)}
              </>
            )}
          </div>
        );
      });
    },
  },
  [EventTypes.GAIN_REROLL]: {
    symbol: Symbols.REROLL,
    borderColor: LogTrimColor_War,
    bodyRenderer: (eg: EventGroup<EventTypes.GAIN_REROLL>, { userByID }) => {
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> gained a reroll.
          </div>
        );
      });
    },
  },
  [EventTypes.USE_REROLL]: {
    symbol: Symbols.REROLL,
    borderColor: LogTrimColor_War,
    bodyRenderer: (eg: EventGroup<EventTypes.USE_REROLL>, { userByID }) => {
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> rolled their dice:{' '}
            {e.payload.rolls.map((roll, i) => (
              <DieView key={i} roll={roll} />
            ))}
          </div>
        );
      });
    },
  },
  [EventTypes.DECLINE_REROLL]: {
    symbol: Symbols.REROLL,
    borderColor: LogTrimColor_War,
    bodyRenderer: (eg: EventGroup<EventTypes.DECLINE_REROLL>, { userByID }) => {
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> keeps their roll.
          </div>
        );
      });
    },
  },
  [EventTypes.GAIN_CARD]: {
    symbol: Symbols.M3_ACQUIRE,
    borderColor: LogTrimColor_Gold,
    bodyRenderer: (
      eg: EventGroup<EventTypes.GAIN_CARD>,
      { userByID, cardByID },
    ) => {
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> obtained{' '}
            <CardNameView
              card={cardByID[e.payload.cardID]}
              renderer={BasicCardRenderer}
            />{' '}
            for {renderCounterDelta(e.payload.cost || {}, 'gold')} and gained{' '}
            {renderCounterDelta(e.payload, 'favor')}
          </div>
        );
      });
    },
  },
  [EventTypes.GAIN_TRIBUTES]: {
    symbol: Symbols.TOKEN_TRIBUTE,
    symbolStyle: {
      filter:
        'invert(15%) sepia(88%) saturate(5759%) hue-rotate(263deg) brightness(83%) contrast(126%)',
    },
    borderColor: LogTrimColor_Favor,
    bodyRenderer: (
      eg: EventGroup<EventTypes.GAIN_TRIBUTES>,
      { userByID, cardByID, tributeCardByID },
    ) => {
      let renderer = MakeHighlightedAgeTributeRenderer(eg.events[0].age);
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> gained{' '}
            {renderCounterDelta({ favor: e.payload.favor }, 'favor')} from
            tributes:{' '}
            <CardsNameView
              cards={e.payload.tributeIDs.map((id) => tributeCardByID[id])}
              renderer={renderer}
            />
            {e.payload.triggeredCards.cardIDs.length > 0 && (
              <>
                {' '}
                and triggered{' '}
                <CardsNameView
                  cards={e.payload.triggeredCards.cardIDs.map(
                    (id) => cardByID[id],
                  )}
                  renderer={BasicCardRenderer}
                />{' '}
                for {renderCounterDelta(e.payload.triggeredCards)}
              </>
            )}
          </div>
        );
      });
    },
  },
  [EventTypes.PRODUCTION]: {
    symbol: Symbols.EFFECT_PRODUCTION,
    borderColor: LogTrimColor_Production,
    bodyRenderer: (eg: EventGroup<EventTypes.PRODUCTION>, { userByID }) => {
      return eg.events.map((e, i) => {
        const user = userByID[e.payload.userID];
        return (
          <div key={i} css={ChatLogItemStyles.entry}>
            <UserName>{user.name}</UserName> produced{' '}
            {renderCounterDelta(e.payload)}
          </div>
        );
      });
    },
  },
  [EventTypes.END_GAME_EFFECTS]: {
    symbol: Symbols.FAVOR,
    borderColor: LogTrimColor_Favor,
    bodyRenderer: (
      eg: EventGroup<EventTypes.END_GAME_EFFECTS>,
      { userByID, cardByID },
    ) => {
      return (
        <>
          {eg.events.map((e, i) => {
            const user = userByID[e.payload.userID];
            return (
              <div key={i} css={ChatLogItemStyles.entry}>
                <UserName>{user.name}</UserName> gained{' '}
                {renderCounterDelta(e.payload.triggeredCards, 'favor')} from{' '}
                <CardsNameView
                  cards={e.payload.triggeredCards.cardIDs.map(
                    (id) => cardByID[id],
                  )}
                  renderer={BasicCardRenderer}
                />
              </div>
            );
          })}
        </>
      );
    },
  },
  [EventTypes.END_GAME_SCORING]: {
    symbol: Symbols.FAVOR,
    borderColor: LogTrimColor_Favor,
    bodyRenderer: (
      eg: EventGroup<EventTypes.END_GAME_SCORING>,
      { userByID, options },
    ) => {
      const pointsPerToken = endOfGamePointsPerToken(options);
      return (
        <>
          <div css={ChatLogItemStyles.entry}>
            Score remaining tokens ({renderCounterDelta({ favor: 1 })} for each{' '}
            {renderCounterDelta({ gold: pointsPerToken.gold })} or{' '}
            {renderCounterDelta({ military: pointsPerToken.military })})
          </div>
          {eg.events.map((e, i) => {
            const user = userByID[e.payload.userID];
            return (
              <div key={i} css={ChatLogItemStyles.entry}>
                <UserName>{user.name}</UserName> gained{' '}
                {renderCounterDelta(e.payload, 'favor')} for{' '}
                {renderCounterDelta(e.payload.cost || {})}
              </div>
            );
          })}
        </>
      );
    },
  },
  [EventTypes.TRIBUTES_RESHUFFLED]: {
    symbol: Symbols.CARD_BACK,
    borderColor: LogTrimColor_Neutral,
    bodyRenderer: () => 'Tributes reshuffled.',
  },
  [EventTypes.DEAL_CARD]: {
    symbol: Symbols.CARD_BACK,
    borderColor: LogTrimColor_Neutral,
    bodyRenderer: (eg: EventGroup<EventTypes.DEAL_CARD>, { cardByID }) => {
      const cards = eg.events.map((event) => {
        return cardByID[event.payload.cardID!];
      });
      return (
        <>
          Dealt <CardsNameView cards={cards} renderer={BasicCardRenderer} /> to
          the trade row
        </>
      );
    },
  },
  [EventTypes.DEAL_TRIBUTE]: {
    symbol: Symbols.CARD_BACK,
    borderColor: LogTrimColor_Neutral,
    bodyRenderer: (
      eg: EventGroup<EventTypes.DEAL_CARD>,
      { tributeCardByID },
    ) => {
      let renderer = MakeHighlightedAgeTributeRenderer(eg.events[0].age);
      const cards = eg.events.map((event) => {
        return tributeCardByID[event.payload.cardID!];
      });
      return (
        <>
          Dealt <CardsNameView cards={cards} renderer={renderer} /> to the
          tribute row
        </>
      );
    },
  },
  [EventTypes.DISCARD_TRIBUTE]: { itemRenderer: () => null },
  [EventTypes.SLIDE_CARD]: {
    symbol: Symbols.CARD_BACK,
    borderColor: LogTrimColor_Neutral,
    bodyRenderer: (eg: EventGroup<EventTypes.SLIDE_CARD>, { cardByID }) => {
      const cards = eg.events.map((event) => {
        return cardByID[event.payload.cardID!];
      });
      return (
        <>
          Discarded <CardsNameView cards={cards} renderer={BasicCardRenderer} />{' '}
          from the trade row
        </>
      );
    },
  },
  [EventTypes.CHAT]: {
    symbol: Symbols.CHAT,
    borderColor: 'rgb(132, 200, 250)',
    bodyRenderer: (eg, { userByID }) => {
      const event = eg.events[0] as GameEventOfType<EventTypes.CHAT>;
      const user = userByID[event.payload.userID];
      return (
        <>
          <UserName>{user.name}</UserName> {event.payload.text}
        </>
      );
    },
  },
};
interface Props {
  game: InflatedGame;
  userByID: { [k: string]: User };
  actionStore?: ActionStore;
}
export const LogView = (props: Props) => {
  const { game, userByID, actionStore } = props;

  const eventGroups = useMemo(() => {
    const eventGroups: EventGroup<EventTypes>[] = [];
    let group: EventGroup<EventTypes> | null = null;
    game.events.forEach((e) => {
      if (group === null) {
        group = { type: e.type, key: `${eventGroups.length}`, events: [e] };
      } else if (group.type == e.type && GROUPED_EVENT_TYPES.has(e.type)) {
        group.events.push(e);
      } else {
        eventGroups.push(group);
        group = { type: e.type, key: `${eventGroups.length}`, events: [e] };
      }
    });
    if (group) {
      eventGroups.push(group);
    }
    eventGroups.reverse();
    return eventGroups;
  }, [props.game.events]);
  return (
    <div css={LogStyles.container}>
      <div css={LogStyles.itemsContainer}>
        {eventGroups.map((eg) => {
          return (
            <EventGroupView
              key={eg.key}
              eg={eg}
              userByID={userByID}
              cardByID={game.cardsByID}
              tributeCardByID={game.tributeCardsByID}
              options={game.options}
            />
          );
        })}
      </div>
    </div>
  );
};

const LogStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 3,
    minHeight: 0,
  }),
  itemsContainer: css({
    overflowY: 'auto',
    backgroundColor: 'rgb(247, 247, 247)',
    marginBottom: 5,
  }),
};

const EventGroupView = React.memo(
  (props: {
    eg: EventGroup<EventTypes>;
    userByID: { [k: string]: User };
    cardByID: { [k: string]: CardWithID };
    tributeCardByID: { [k: string]: TributeCardWithID };
    options: GameOptions;
  }) => {
    const { eg, userByID, cardByID, tributeCardByID, options } = props;
    const renderer = EventTypeToRenderer[eg.type];
    if (!renderer) {
      return null;
    }
    if ('bodyRenderer' in renderer) {
      const body = renderer.bodyRenderer(eg, {
        userByID,
        cardByID,
        tributeCardByID,
        options,
      });
      if (!body) {
        return null;
      }
      return (
        <ChatLogItem
          symbol={renderer.symbol}
          symbolStyle={renderer.symbolStyle}
          style={{ borderColor: renderer.borderColor }}
        >
          {body}
        </ChatLogItem>
      );
    } else {
      return renderer.itemRenderer(eg);
    }
  },
);

function ChatLogDivider(props: {
  text: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  const { text } = props;
  return (
    <div
      css={[ChatLogItemStyles.container, ChatLogItemStyles.divider]}
      className={props.className}
      style={props.style}
    >
      <div css={ChatLogItemStyles.dividerContent}>{text}</div>
    </div>
  );
}

function ChatLogItem(props: {
  className?: string;
  style?: React.CSSProperties;

  symbol: Symbols;
  symbolStyle?: React.CSSProperties;

  text?: React.ReactNode;
  body?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { text, body, children } = props;
  return (
    <div
      css={ChatLogItemStyles.container}
      className={props.className}
      style={props.style}
    >
      <div css={ChatLogItemStyles.symbolContainer}>
        <SymbolView
          symbol={props.symbol}
          css={ChatLogItemStyles.symbol}
          style={props.symbolStyle}
        />
      </div>
      <div css={ChatLogItemStyles.contentContainer}>
        {text && <div css={ChatLogItemStyles.contentItemWrapper}>{text}</div>}
        {body && <div css={ChatLogItemStyles.contentItemWrapper}>{body}</div>}
        {children && (
          <div css={ChatLogItemStyles.contentItemWrapper}>{children}</div>
        )}
      </div>
    </div>
  );
}
const ChatLogItemStyles = {
  container: css({
    display: 'flex',
    padding: 5,
    margin: 5,
    backgroundColor: '#e0e0e0',
    borderLeft: 'solid 2px rgba(0, 0, 0, 0)',
    fontSize: 14,
  }),
  entry: css({
    marginBottom: 2,
    '&:last-child': {
      marginBottom: 0,
    },
  }),
  symbolContainer: css({
    marginLeft: 1,
    alignSelf: 'center',
  }),
  symbol: css({
    width: 15,
    height: 'auto',
  }),
  contentContainer: css({
    marginLeft: 10,
    maxWidth: '90%',
    paddingRight: 10,
    wordWrap: 'break-word',
  }),
  contentItemWrapper: css({
    padding: 0,
    margin: 0,
  }),

  divider: css({
    maxHeight: 8,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: '#e0e3e9',
    fontSize: 13,
    textAlign: 'center',
    display: 'block',
  }),
  dividerContent: css({
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: -3,
  }),
};

function renderCounterDelta(
  counters: Partial<CounterDelta>,
  forceResource?: Resource,
): React.ReactNode {
  return Resources.map((resource: Resource) => {
    if (
      resource === forceResource ||
      (counters[resource] && counters[resource] > 0)
    ) {
      return (
        <span key={resource} css={CounterDeltaStyles.container}>
          {counters[resource] || 0}
          <SymbolView
            symbol={ResourceToSymbol[resource]}
            css={CounterDeltaStyles.symbol}
          />
        </span>
      );
    }
    return null;
  });
}
const CounterDeltaStyles = {
  container: css({
    marginLeft: 2,
  }),
  symbol: css({
    height: 12,
    width: 12,
    marginLeft: 1,
  }),
};

function UserName(props: {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) {
  return (
    <span css={UserNameStyle} className={props.className} style={props.style}>
      {props.children}
    </span>
  );
}
const UserNameStyle = css({
  fontWeight: 'bold',
});

function CardsNameView<T extends { id: string; name: string }>(props: {
  cards: T[];
  renderer: HoverCardRenderer;
}) {
  let card_name_views: React.ReactNode[] = [];
  props.cards.forEach((card, i) => {
    if (i !== 0) {
      card_name_views.push(', ');
    }
    card_name_views.push(
      <CardNameView key={i} card={card} renderer={props.renderer} />,
    );
  });
  return <>{card_name_views}</>;
}
function CardNameView(props: {
  card: any;
  renderer: HoverCardRenderer;
  className?: string;
  style?: React.CSSProperties;
}) {
  const onMouseEnter = () => {
    HoverCardStore.setPositionLeft(true);
    HoverCardStore.setCard(props.card, props.renderer);
  };

  const onMouseLeave = () => {
    HoverCardStore.setCard(null, null);
  };

  return (
    <span
      css={CardNameStyle}
      className={props.className}
      style={props.style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {props.card.name}
    </span>
  );
}
const CardNameStyle = css({
  fontWeight: 'bold',
  color: 'rgb(70, 90, 195)',
  textDecoration: 'underline',
  '&:hover': {
    color: 'rgb(35, 55, 160)',
  },
});

export function ConflictResultsView(props: {
  event: GameEventOfType<EventTypes.CONFLICT_RESULTS>;
  userByID: { [k: string]: User };
  cardByID: { [k: string]: CardWithID };
}) {
  const { event, userByID, cardByID } = props;
  const rounds = [...event.payload.rounds];
  const card = cardByID[event.payload.cardID];
  const firstRound = rounds[0];
  let dice_count = event.age;
  let playerIDToWinPercentage = _.mapObject(firstRound, (result, player_id) => {
    let self_bonus = result.bonus;
    let opponent_dice_bonus_tuples: [number, number][] = _.map(
      _.omit(firstRound, player_id),
      (result: any) => {
        return [dice_count, result.bonus];
      },
    );
    let raw_percent = Probability.probabilityWinningMultiBattle(
      dice_count,
      self_bonus,
      opponent_dice_bonus_tuples,
    );
    return Math.floor(Math.round(raw_percent * 100000) / 1000);
  });

  rounds.reverse();
  const roundViews = rounds.map((results_by_player_id, round_i) => {
    let results = Object.values(results_by_player_id);
    results.sort((a, b) => b.total - a.total);

    const playerRows = results.map((result, player_i) => {
      const user = userByID[result.playerID];
      const rendered_rolls = result.rolls.map((roll, round_i) => (
        <DieView roll={roll} key={`roll${round_i}`} />
      ));
      const is_winner =
        round_i === 0 && result.playerID === event.payload.winningPlayerID;
      const is_reroller =
        event.payload.playerIDsWithRerolls &&
        event.payload.playerIDsWithRerolls.includes(result.playerID);
      const show_percent =
        is_winner || (round_i === rounds.length - 1 && results.length > 1);
      const win_percent = show_percent
        ? ` (${playerIDToWinPercentage[result.playerID]}%)`
        : '';
      const textColor = is_winner ? 'rgb(225, 95, 50)' : '';
      return (
        <div key={player_i} css={ConflictResultsStyle.playerRow}>
          <div>
            <UserName style={{ color: textColor }}>
              {user.name}
              {is_reroller ? (
                <SymbolView
                  css={ChatLogItemStyles.symbol}
                  style={{ marginLeft: 2 }}
                  symbol={Symbols.REROLL}
                />
              ) : null}
            </UserName>
            :
          </div>
          <div>
            {rendered_rolls} + {result.bonus} = {result.total}
          </div>
          <div>{win_percent}</div>
        </div>
      );
    });
    return (
      <div key={round_i} css={ConflictResultsStyle.rollRound}>
        {playerRows}
      </div>
    );
  });

  return (
    <>
      <div css={ChatLogItemStyles.entry}>
        Clash results for{' '}
        <CardNameView card={card} renderer={BasicCardRenderer} />
      </div>
      {roundViews}
    </>
  );
}
const ConflictResultsStyle = {
  rollRound: css({
    '&:not(:last-child)': {
      '&::after': {
        display: 'block',
        content: '""',
        margin: 5,
        marginLeft: 10,
        marginRight: 15,
        borderBottom: '1px solid rgb(160, 160, 160)',
        height: 1,
        width: '100%',
      },
    },
  }),
  playerRow: css({
    display: 'grid',
    gridTemplateColumns: '102px 105px 44px',
    marginBottom: 2,
    '&:last-child': {
      marginBottom: 0,
    },
  }),
};
